import { MENU_ARR } from "../config/menu.config.js";
/**
 * selected: {
 *  [key: "root" | Key]: ChildrenKey
 * }
 */
function getDefaultKey(children) {
    return children[0].key;
}
export const ROOT = "root",
    NONE = "none";
const selected = {};
// 默认选中一个实体
selected[ROOT] = getDefaultKey(MENU_ARR);
MENU_ARR.forEach((FirstMenu) => {
    // 组件默认不选中
    // selected[FirstMenu.key] = $$.platform.mobile ? NONE : getDefaultKey(FirstMenu.children);
    selected[FirstMenu.key] = NONE;
    FirstMenu.children.forEach((SecondMenu) => {
        // 组件默认选中一个颜色
        selected[SecondMenu.key] = getDefaultKey(SecondMenu.children);
    });
});

const getLevelData = (function () {
    function getLevelSelKey(selected, level) {
        let key = ROOT;
        for (let i = 0; i <= level; i++) {
            key = selected[key];
        }
        return key;
    }
    return function ({ selected, menus, level }) {
        let children = menus,
            curSelKey,
            data;
        for (let i = 0; i <= level; i++) {
            curSelKey = getLevelSelKey(selected, i);
            data = children.find((d) => d.key === curSelKey);
            if (!data) {
                return null;
            }
            children = data.children;
        }
        return data;
    };
})();

export default {
    namespaced: true,
    state: {
        menus: MENU_ARR,
        ...selected,
    },
    mutations: {
        setSelected(state, { parentKey, selectedKey }) {
            state[parentKey] = selectedKey;
        },
    },
    actions: {
        setMain({ commit, state }, { key, timeline }) {
            commit("setSelected", { parentKey: ROOT, selectedKey: key });
            $$.app.runInteraction(timeline);
        },
        setComponent({ commit, getters }, { key, timeline }) {
            if (getters.activeDataArr[1] && key === getters.activeDataArr[1].key) {
                commit("setSelected", { parentKey: getters.activeDataArr[0].key, selectedKey: NONE });
            } else {
                commit("setSelected", { parentKey: getters.activeDataArr[0].key, selectedKey: key });
            }
        },
        setMaterial({ commit, getters }, { key, timeline }) {
            commit("setSelected", { parentKey: getters.activeDataArr[1].key, selectedKey: key });
            $$.app.runInteraction(timeline);
        },
    },
    getters: {
        activeDataArr(state) {
            return Array(3)
                .fill(1)
                .map((item, index) => {
                    return getLevelData({ menus: state.menus, selected: state, level: index });
                });
        },
        activeMainKey(state) {
            return state[ROOT];
        },
    },
};
