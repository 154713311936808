var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:{
        Menu: true,
        PcMenu: !_vm.$$.platform.mobile,
    }},[_c('header',[_c('ul',{staticClass:"main-menu"},_vm._l((_vm.mainArr),function(mainItem){return _c('li',{key:mainItem.key,staticClass:"main-item",class:{
                    'main-item-active': mainItem.key === _vm.activeMainKey,
                },on:{"click":function($event){return _vm.setMain(mainItem)}}},[_vm._v(" "+_vm._s(mainItem.nameEn)+" ")])}),0)]),_c('footer',{staticClass:"footer"},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.activeMaterialName),expression:"activeMaterialName"}],staticClass:"material-name"},[_vm._v(_vm._s(_vm.activeMaterialName))]),_c('ul',{staticClass:"materials"},_vm._l((_vm.materialArr),function(materialItem){return _c('li',{key:materialItem.key,class:{
                    'material-item': true,
                    'material-item-active': materialItem.key === _vm.activeMaterialKey,
                },style:({
                    backgroundImage:("url(" + (_vm.$$.assets(("images/material/" + (_vm.activeDataArr[0].key) + "/" + (_vm.activeDataArr[1].key) + "/" + (materialItem.key) + ".png"))) + ")")
                }),on:{"mousedown":function($event){return _vm.start(materialItem, $event)},"mousemove":_vm.move,"mouseup":_vm.end,"touchstart":function($event){return _vm.start(materialItem, $event)},"touchmove":_vm.move,"touchend":_vm.end}})}),0),_c('div',{staticClass:"buttons"},[_c('ul',{staticClass:"components"},_vm._l((_vm.componentArr),function(componentItem){return _c('li',{key:componentItem.key,class:{
                        'component-item': true,
                        'component-item-active': _vm.activeComponentKey === componentItem.key,
                    },on:{"click":function($event){return _vm.setComponent(componentItem)}}},[_vm._v(" "+_vm._s(componentItem.nameEn)+" ")])}),0),_c('div',{staticClass:"power"},[_c('button',{class:{ 'power-button': true, 'power-button-active': !_vm.doorStatus },on:{"click":function($event){return _vm.setDoorStatus(Object.assign({}, _vm.activeDataArr[0], {status: false}))}}},[_vm._v(" Close ")]),_c('div',{staticClass:"separator"}),_c('button',{class:{ 'power-button': true, 'power-button-active': _vm.doorStatus },on:{"click":function($event){return _vm.setDoorStatus(Object.assign({}, _vm.activeDataArr[0], {status: true}))}}},[_vm._v(" Open ")])])])]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.materialDetailShow),expression:"materialDetailShow"}],staticClass:"materialDetail"},[_c('div',{staticClass:"materialDetail-box"},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.activeMaterialName),expression:"activeMaterialName"}],staticClass:"material-name"},[_vm._v(_vm._s(_vm.activeMaterialName))]),_c('img',{staticClass:"materialDetail-close",attrs:{"src":_vm.closeMaterialDetailImg,"alt":"关闭"},on:{"click":_vm.closeMaterialDetail}}),_c('img',{staticClass:"materialDetail-main",attrs:{"src":_vm.activeMaterialImg,"alt":_vm.activeMaterialName}})])])])}
var staticRenderFns = []

export { render, staticRenderFns }