<template>
    <div class="Loading">
        <img :src="$$.assets('images/logo.png')" alt="homa" class="logo" />
        <div class="loading-box">
            <div class="loading-content" :style="{ width: `${progress}%` }"></div>
        </div>
        <span class="loading-text">loading......</span>
    </div>
</template>

<script>
import { mapState } from "vuex";
export default {
    computed: {
        ...mapState("Loading", ["progress"]),
    },
};
</script>

<style scoped lang="less">
@import "../../less/global/index.less";
.Loading {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 10;
    background-color: #f2f2f2;

    .logo {
        margin-bottom: (60 / @rem);
        height: (56 / @rem);
    }
    .loading {
        &-box {
            position: relative;
            width: (350 / @rem);
            height: (10 / @rem);
            background-color: rgba(100, 100, 100, 0.8);
            border-radius: (5 / @rem);
            overflow: hidden;
        }
        &-content {
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            width: 0%;
            background-color: #000;
            transition-duration: 0.1s;
            border-radius: (5 / @rem);
        }
        &-text {
            margin-top: (45 / @rem);
            color: #000;
            font-size: (20 / @rem);
        }
    }
}
</style>
