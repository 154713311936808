export const SCENE_STATUS = {
    LOADING: "loading",
    LOADED: "loaded",
};
export const SCENE_SIGNAL = {
    ON_LOAD_START: "onLoadStart",
    ON_LOAD_END: "onLoadEnd",
    ON_PROGRESS_UPDATE: "onProgressUpdate",
};
const textureField = {
    aoMap: "AO",
    diffuseMap: "Diffuse",
    specularMap: "Specular",
    metalnessMap: "Metalness",
    glossMap: "Gloss",
    emissiveMap: "Emissive",
    normalMap: "Normal",
    opacityMap: "Opacity",
    lightMap: "Light",
  };
/**
 * Scene: 场景管理
 */
export default class Scene {
    constructor(context) {
        this.context = context;
    }

    defaultID;

    status = {};

    configuration = {};

    load(sceneData, options) {
        const app = this.context._app;
        const scope = this;
        if (this.status[options.id]) {
            return (config) => {
                this.addConfiguration(options.id, config);
                this.onLoadStart(options.id);
                this.onLoadEnd(options.id);
            };
        }
        app.loadData(sceneData, {
            id: options.id,
            postRendering: false,
            ...options,
            dataProcessingHook: (data) => {
                let _data = scope.modifyData(data);
                if(options.dataProcessingHook) {
                    _data = options.dataProcessingHook(_data)
                }
                return _data;
            },
        });
        return this.addConfiguration.bind(this, options.id);
    }

    emit(sceneID, signal, ...argus) {
        if (this.configuration[sceneID]?.[signal]) {
            this.configuration[sceneID][signal](...argus);
        }
    }

    addConfiguration(id, configs) {
        if (configs.isDefault && !this.defaultID) {
            this.defaultID = id;
        }
        this.configuration[id] = configs;
    }

    onProgressUpdate(sceneID, progress) {
        this.emit(
            sceneID,
            SCENE_SIGNAL.ON_PROGRESS_UPDATE,
            progress.progress,
            progress
        );
    }

    onLoadStart(sceneID) {
        this.emit(sceneID, SCENE_SIGNAL.ON_LOAD_START);
        this.status[sceneID] = SCENE_STATUS.LOADING;
    }

    onLoadEnd(sceneID) {
        this.emit(sceneID, SCENE_SIGNAL.ON_LOAD_END);
        this.status[sceneID] = SCENE_STATUS.LOADED;
        delete this.configuration[sceneID];
    }

    modifyData(data) {
        // 摄像机反转
        const targetMaterialList = {};
        data.entities.forEach((entity) => {
            if (
                entity.type === "annotation" &&
                entity.extensions.resourceType === "materials"
            ) {
                Object.keys(entity.extensions.materials).forEach((key) => {
                    const uuid = entity.extensions.materials[key];
                    if (uuid) {
                        targetMaterialList[uuid] = uuid;
                    }
                });
            }
        });
        data.resources = data.resources.map((resource) => {
            if (
                resource.type === "material" &&
                targetMaterialList[resource.uuid]
            ) {
                Object.keys(textureField).forEach((field) => {
                    if (resource.data[field]) {
                        resource.data[field + "ReverseMode"] = 1;
                    }
                });
            }
            return resource;
        });
        // 筛选卖点进场摄像机事件 采取就近旋转
        data.events
            .filter((e) => e.type === 2 && e.name === "卖点视角进场")
            .forEach((e) => (e.data.nearest = true));
        return data;
    }
}
