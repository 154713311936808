import Vue from "vue";
import Vuex from "vuex";
import modules from "./modules.js";
import actions from "./actions.js";
Vue.use(Vuex);
// menu type
const MENU = {
    INTERIOR: "interior",
    APPEARANCE_COLOR: "appearance_color",
    INTERIOR_COLOR: "interior_color",
    ANIMATION: "animation",
    SIZE: "size",
    EMPTY: "",
};
export default new Vuex.Store({
    state: {
        canvas: null,
        validate: false,
        data: null
    },
    getters: {
        isMotion(state) {},
    },
    mutations: {
        setValue(state, data) {
            Object.entries(data).forEach(([key, value]) => {
                state[key] = value;
            });
        },
    },
    actions,
    ...modules,
});
