import Vue from "vue";
import App from "./app/PcApp.vue";
import MobileApp from "./app/MobileApp.vue";
import $$ from "./core/application";
import store from "./store/index";
// import "./assets/font/iconfont.css";
import "./assets/css/base.css";
import less from "less";
import "./less/global/index.less";
import "vant/lib/index.css";
import { ShareSheet } from 'vant';
Vue.use(ShareSheet);

Vue.prototype.$$ = $$;
Vue.use(less); // 全局启用less
Vue.config.productionTip = false;
// let mainApp = $$.platform.mobile ? MobileApp : App;
let mainApp = MobileApp;

new Vue({
  render: (h) => h(mainApp),
  store,
}).$mount("#app");
