export default {
    namespaced: true,
    state: {
        loaded: false,
        progress: 0,
        interiorLoading: false,
    },
    mutations: {
        updateProgress(state, value) {
            state.progress = value;
        },
        loadSucess(state) {
            state.loaded = true;
            const id = $$.platform.mobile ? "2363655820565020882" : "2363656000179798099";
            $$.app.runInteraction(id);
        },
        setInteriorLoading(state, status) {
            state.interiorLoading = status;
        },
    },
    actions: {},
    getters: {},
};
