<template>
    <div class="model_container">
        <canvas id="canvas" ref="canvas"></canvas>
    </div>
</template>

<script>
import { mapActions } from "vuex";

export default {
    name: "Viewer",
    data() {
        return {};
    },
    mounted() {
        this.initialize({ canvas: this.$refs.canvas });
    },
    methods: {
        ...mapActions(["initialize"])
    },
};
</script>

<style scoped lang="less">
@import "../../less/global/index.less";
.model_container {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    z-index: 1;
    // background-image: url("../../assets/images/alone/m/main_bg.png");
    background-color: #fff;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    #canvas {
        width: 100% !important;
        height: 100% !important;
    }
}
</style>
