import { MENU_ARR } from "../config/menu.config.js";
const status = {};
MENU_ARR.forEach(menu => {
    status[menu.key] = false
})
export default {
    namespaced: true,
    state: {
        ...status
    },
    mutations: {
        setDoorStatus(state, {key, status}) {
            state[key] = status
        }
    },
    actions: {
        setDoorStatus({commit, state}, {key , openDoorTimeline, closeDoorTimeline, status}) {
            if(state[key] === status) return;
            if(status){
                $$.app.runInteraction(openDoorTimeline);
            }else {
                $$.app.runInteraction(closeDoorTimeline);
            }
            commit("setDoorStatus", {key, status})
        }
    },
    getters: {},
};