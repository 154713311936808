<template>
    <div id="app">
        <Loading v-if="!loaded" />
        <Logo />
        <Viewer />
        <Menu v-if="loaded" />
    </div>
</template>

<script>
    import { mapState, mapMutations } from "vuex";
    import Viewer from "../components/Mobile/Viewer.vue";
    import Logo from "../components/Mobile/Logo.vue";
    import Menu from "../components/Mobile/Menu.vue";
    import Loading from "../components/Mobile/Loading.vue"

    export default {
        name: "App",
        components: { Viewer, Logo, Menu, Loading },
        computed: {
            ...mapState("Loading", ["loaded"])
        },
        mounted() {
            document.addEventListener(
                "touchmove",
                function (e) {
                    e.preventDefault(); //阻止默认的处理方式(阻止下拉滑动的效果)
                },
                { passive: false }
            ); //passive 参数不能省略，用来兼容ios和android
        },
    };
</script>

<style scoped>
    @import url(../assets/css/base.css);
    .stop_mask {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 100;
    }
</style>
