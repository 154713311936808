import CONFIG from "./config"; // 配置抽离
import {platform} from "./utils";
import ViewerApp from "../store/app/index";
class Application {
    config = CONFIG;
    platform = platform();
    app = new ViewerApp();
    constructor() {}
    // 生成Asset Uri
    assets(file) {
        return `${this.config.assetPath}${file}`;
    }

    assetVideo(file) {
        return `${this.config.assetPath}${file}`;
    }
}

const application = new Application();
global.$$ = application;
export default application;
export { Application };
