<template>
    <div
        :class="{
            Password: true,
            PasswordMobile: $$.platform.mobile,
        }"
        @click="submit1"
    >
        <!-- <img :src="$$.assets('images/logo.png')" alt="homa" class="logo" />
        <div class="input-box">
            <img class="input-box-icon" alt="password" :src="$$.assets('images/password.png')" />
            <input
                class="password"
                type="password"
                placeholder="Enter password"
                v-model="password"
                maxlength="6"
                @input="input"
                @keypress="keypress"
            />
            <img class="close" :src="$$.assets('images/close.png')" alt="clear" @click="clear" v-show="clearIcon" />
        </div>
        <button class="submit" @click="submit">OK</button> -->
        <img class="intro" :src="indexImg" alt="介绍" />
    </div>
</template>

<script>
export default {
    name: "Password",
    data() {
        return {
            password: "",
            clearIcon: false,
        };
    },
    methods: {
        keypress(e) {
            const evt = window.event || e;
            if (evt.keyCode == 13) {
                this.submit();
            }
        },
        input(e) {
            let value = e.target.value;
            // if(value.length > this.password.length) {
            //     this.password += e.data;
            // }else {
            //     this.password = this.password.substring(0, value.length)
            // }
            this.clearIcon = Boolean(value);
        },
        clear() {
            this.password = "";
            this.clearIcon = false;
        },
        submit() {
            if (this.password === "002668") {
                this.$store.dispatch("validate");
            }
            this.clear();
        },
        submit1() {
            this.$store.dispatch("validate");
        },
    },
    computed: {
        indexImg() {
            return $$.platform.mobile ? $$.assets(`images/detailMo/index.png`) : $$.assets(`images/detailPc/index.png`);
        },
    },
};
</script>

<style scoped lang="less">
@import "../../less/global/index.less";
.Password {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 12;
    background-color: #f2f2f2;

    .logo {
        margin-bottom: (60 / @rem);
        height: (56 / @rem);
    }

    .input-box {
        position: relative;
        display: flex;
        align-items: center;
        width: (260 / @rem);
        height: (34 / @rem);
        background-color: rgb(45, 45, 45);
        border: 1px solid rgb(53, 53, 53);
        box-sizing: content-box;
        &-icon {
            @margin-length: (8 / @rem);
            height: calc(100% - @margin-length);
            margin: (4 / @rem) 0;
            margin-right: (6 / @rem);
            padding: (2 / @rem) (4 / @rem);
            border-right: 1px solid rgb(120, 120, 120);
        }

        .password {
            margin: 0;
            padding: 1px 2px;
            flex: 1;
            width: 0px; // input有个默认宽度一直去不掉
            height: 80%;
            background-color: transparent;
            color: #fff;
            font-size: (24 / @rem);
            border: unset;
        }

        .close {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            right: (-32 / @rem);
            width: (25 / @rem);
            height: (25 / @rem);
        }
    }

    .submit {
        margin-top: (20 / @rem);
        padding: unset;
        font-size: (20 / @rem);
        border: 1px solid;
        box-sizing: content-box;
        width: (260 / @rem);
        height: (34 / @rem);
        line-height: (34 / @rem);
        color: rgb(65, 32, 13);
        background-color: rgb(255, 96, 38);
    }

    .intro {
        height: 100%;
        max-width: unset;
        max-height: unset;
    }
}
.PasswordMobile {
    .intro {
        max-width: 100%;
        max-height: 100%;
        height: unset;
    }
    // padding-bottom: 10%;
    .input-box {
        width: (390 / @rem);
        height: (50 / @rem);

        &-icon {
            @margin-length: (14 / @rem);
            height: calc(100% - @margin-length);
        }

        .password {
            height: (40 / @rem);
            line-height: (40 / @rem);
            font-size: (28 / @rem);
        }

        .close {
            right: (-50 / @rem);
            width: (38 / @rem);
            height: (38 / @rem);
        }
    }

    .submit {
        font-size: (30 / @rem);
        width: (390 / @rem);
        height: (50 / @rem);
        line-height: (50 / @rem);
    }
}
</style>
