/**
 * Author: joker
 * 命名规则
 * 常量: 全大写， 下划线连接
 * 内部变量: (定义) 不需要被外界访问的变量，此处没有实现private
 * 类名： 首字母大写， 驼峰规则
 */

import Scene from "./scene.js";
import Annotation from "./annotation.js";
import Interaction from "./interaction.js";

export { SCENE_STATUS, SCENE_SIGNAL } from "./scene.js";
export { TIMELINE_STATUS } from "./interaction.js";


/**
 * Author: joker
 * App
 * 分发层， 所有api都挂载到app上，在这边再进行分发， 同时做一些全局处理：如realibox.studio是否实例
 */
export default class App {
    _scene = new Scene(this);
    _annotation = new Annotation(this);
    _interaction = new Interaction(this);
    camera;
    // engine app
    _app;
    // canvas
    _canvas;

    _checkApp() {
        if (!this._app)
            throw new Error("You can't emit loadScene before initilize");
    }

    _initializeEvents() {
        this._checkApp();
        this._app.events.on(
            "edk:scene:resource:preload:progress",
            this._scene.onProgressUpdate,
            this._scene
        );
        this._app.events.on(
            "edk:scene:resource:preload:end",
            this._scene.onLoadEnd,
            this._scene
        );
        this._app.events.on(
            "edk:scene:resource:preload:start",
            this._scene.onLoadStart,
            this._scene
        );

        this._app.events.on(
            "edk:scene:interaction:timeline:stop",
            this._interaction.onStop,
            this._interaction
        );
        this._app.events.on(
            "edk:scene:interaction:timeline:loop",
            this._interaction.onLoop,
            this._interaction
        );

        this._app.events.on(
            "edk:plugin:annotation:click",
            this._annotation.onAnnotaionClick,
            this._annotation
        );
        this._app.events.on(
            "edk:plugin:annotation:enter",
            this._annotation.onAnnotationEnter,
            this._annotation
        );
        this._app.events.on(
            "edk:plugin:annotation:leave",
            this._annotation.onAnnotationLeave,
            this._annotation
        );
        this._app.events.on(
            "edk:plugin:entity:selector:click",
            this._annotation.onEntityClick,
            this._annotation
        );
    }

    initialize(canvas, options = {}) {
        const targetOptions = {
            dracoDecoderConfig: {
                decoderFilePath: global.$$.assets("libs/draco/draco_decoder.js"),
                wasmWrapperFilePath: global.$$.assets(
                    "libs/draco/draco_wasm_wrapper.js"
                ),
                wasmFilePath: global.$$.assets("libs/draco/draco_decoder.wasm"),
            },
            ...options,
        };
        this._canvas = canvas;
        this._app = new global.realibox.studio(canvas, targetOptions);
        global.app = this
        this._initializeEvents();
    }

    loadScene(sceneData, options) {
        this._checkApp();
        return this._scene.load(sceneData, options);
    }

    /**
     * 执行一条时间线： runInteraction(id) /runInteraction(id, sceneID) / runInteraction([id])
     *                 / runInteraction([[id, sceneID]])
     * 执行多条时间线: runInteraction([id, id]) / runInteraction([id, [id, sceneID]])
     *                 / runInteraction([[id, sceneID], [id, sceneID]])
     *                 / runInteraction([id,id,[id, sceneID]], sceneID)
     */
    runInteraction(id, sceneID) {
        this._checkApp();
        return this._interaction.run(id, sceneID);
    }

    addAnnotationListener() {
        this._annotation.addAnnotationsListener(...arguments);
    }
    addEntitiesListener() {
        this._annotation.addEntitiesListener(...arguments);
    }
    getScene(sceneID) {
        return this._scene.status[sceneID];
    }

    clearInteraction() {
        this._interaction.clear(...arguments)
    }

    getAliveInteraction(sceneID, timelineID) {
        sceneID = sceneID || this._scene.defaultID;

        if (timelineID && sceneID) {
            return Boolean(
                this._interaction.timelinesStatus[sceneID][timelineID]
            );
        } else if (sceneID) {
            return Object.keys(this._interaction.timelinesStatus[sceneID]).map(
                (timelineID) => [timelineID, sceneID]
            );
        } else {
            return Object.keys(this._interaction.timelinesStatus).reduce(
                (target, sceneID) => {
                    target = target.concat(
                        Object.keys(
                            this._interaction.timelinesStatus[sceneID]
                        ).map((timelineID) => [timelineID, sceneID])
                    );
                },
                []
            );
        }
    }

    _cameraCache;

    getCamera() {
        if (!this._cameraCache)
            this._cameraCache = this._app.events.call("edk:render:camera:get");
        return this._cameraCache;
    }
}
