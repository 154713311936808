export default class Utils {
    loadScript(url, id, attributes = {}, once = false) {
        return new Promise((resolve, reject) => {
            let element = document.getElementById(id);
            if (element) {
                if (once) {
                    resolve();
                    return;
                }
                document.body.removeChild(element);
            } else {
                element = document.createElement("script");
            }
            element.id = id;
            element.type = "text/javascript";
            element.src = url;

            if (element.readyState) {
                element.onreadystatechange = function () {
                    if (
                        element.readyState == "loaded" ||
                        element.readyState == "complete"
                    ) {
                        element.onreadystatechange = null;
                        resolve();
                    }
                };
            } else {
                element.onload = function () {
                    resolve();
                };
                element.onerror = function () {
                    reject();
                };
            }

            Object.keys(attributes).forEach((attribute) => {
                // 设置属性
                element.setAttribute(attribute, attributes[attribute]);
            });
            // 添加到文档结尾
            document.body.appendChild(element);
        });
    }

    platform() {
        let platform = {
            desktop: false,
            mobile: false,
            ios: false,
            android: false,
            tablet: false,
            windows: false,
            cocoonjs: false,
            xbox: false,
            gamepads: false,
            chrome: false,
            firefox: false,
            touch: false,
        };
        let ua = navigator.userAgent;
        if (/(windows|mac os|linux|cros)/i.test(ua)) {
            platform.desktop = true;
        }
        if (/xbox/i.test(ua)) {
            platform.xbox = true;
        }
        if (/(?:Firefox)/.test(ua)) {
            platform.firefox = true;
        }
        if (/(?:Chrome|CriOS)/.test(ua)) {
            platform.chrome = true;
        }
        if (/(windows phone|iemobile|wpdesktop)/i.test(ua)) {
            platform.desktop = false;
            platform.mobile = true;
            platform.windows = true;
        } else {
            if (/android/i.test(ua)) {
                platform.desktop = false;
                platform.mobile = true;
                platform.android = true;
            } else {
                if (/ip([ao]d|hone)/i.test(ua)) {
                    platform.desktop = false;
                    platform.mobile = true;
                    platform.ios = true;
                }
            }
        }

        platform.tablet =
            /(?:iPad|PlayBook)/.test(ua) ||
            (platform.android && !/(?:Mobile)/.test(ua)) ||
            (platform.firefox && /(?:Tablet)/.test(ua));

        platform.touch = "ontouchstart" in window;
        platform.gamepads = "getGamepads" in navigator;
        return platform;
    }

    guid() {
        return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(
            /[xy]/g,
            function (c) {
                const r = (Math.random() * 16) | 0,
                    v = c === "x" ? r : (r & 0x3) | 0x8;
                return v.toString(16);
            }
        );
    }

    adaptResourceUrl(url) {
        if (
            !!url &&
            url.indexOf("data:") === -1 &&
            url.indexOf("blob:") === -1
        ) {
            if (url.indexOf("http://") === 0) {
                url = url.substring(5);
            }
            if (url.indexOf("https://") === 0) {
                url = url.substring(6);
            }
            if (url.indexOf("//") === -1 || url.indexOf("//") !== 0) {
                url = "//" + url;
            }
        }
        return url;
    }
    adaptOssImageUrl(url, options) {
        if (typeof url === "string") {
            url = this.adaptResourceUrl(url);
            if (url.indexOf("//") === 0 && options) {
                const split = url.indexOf("?") !== -1 ? `&` : `?`;
                let resize = "resize";
                if (options.width) {
                    resize += `,w_${options.width || 128}`;
                }
                if (options.height) {
                    resize += `,h_${options.height || 128}`;
                }
                url = `${url}${split}x-oss-process=image/${resize}/quality,Q_100`;
            }
            return url;
        } else {
            return url;
        }
    }
    getQueryString(name) {
        const reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)", "i");
        const r = global.window.location.search.substr(1).match(reg);
        if (r != null) {
            return unescape(r[2]);
        }
        return null;
    }

    debounce(fn, time) {
        let timer = null;
        return function (...args) {
            if (timer) clearTimeout(timer);
            timer = setTimeout(() => {
                fn(...args);
            }, time);
        };
    }
}

export const xmlToJson = (xml) => {
    const XmlToJson = function () {
        // 构造函数
    };
    XmlToJson.prototype.setXml = function (xml) {
        if (xml && typeof xml === "string") {
            this.xml = document.createElement("div");
            this.xml.innerHTML = xml;
            this.xml = this.xml.getElementsByTagName("*")[0];
        } else if (typeof xml === "object") {
            this.xml = xml;
        }
    };
    XmlToJson.prototype.getXml = function () {
        return this.xml;
    };
    XmlToJson.prototype.parse = function (xml) {
        this.setXml(xml);
        return this.convert(this.xml);
    };
    XmlToJson.prototype.convert = function (xml) {
        if (xml.nodeType != 1) {
            return null;
        }
        const obj = {};
        obj.xtype = xml.nodeName.toLowerCase();
        const nodeValue = (xml.textContent || "")
            .replace(/(\r|\n)/g, "")
            .replace(/^\s+|\s+$/g, "");

        if (nodeValue && xml.childNodes.length == 1) {
            obj.text = nodeValue;
        }
        if (xml.attributes.length > 0) {
            for (let j = 0; j < xml.attributes.length; j++) {
                const attribute = xml.attributes.item(j);
                obj[attribute.nodeName] = attribute.nodeValue;
            }
        }
        if (xml.childNodes.length > 0) {
            const items = [];
            for (let i = 0; i < xml.childNodes.length; i++) {
                const node = xml.childNodes.item(i);
                const item = this.convert(node);
                if (item) {
                    items.push(item);
                }
            }
            if (items.length > 0) {
                obj.items = items;
            }
        }
        return obj;
    };

    // const xmlObj = new

    return new XmlToJson().parse(xml);
};

export const platform = () => {
    const platform = {
        desktop: false,
        mobile: false,
        ios: false,
        android: false,
        tablet: false,
        windows: false,
        cocoonjs: false,
        xbox: false,
        gamepads: false,
        chrome: false,
        firefox: false,
        touch: false,
    };
    const ua = navigator.userAgent;
    if (/(windows|mac os|linux|cros)/i.test(ua)) {
        platform.desktop = true;
    }
    if (/xbox/i.test(ua)) {
        platform.xbox = true;
    }
    if (/(?:Firefox)/.test(ua)) {
        platform.firefox = true;
    }
    if (/(?:Chrome|CriOS)/.test(ua)) {
        platform.chrome = true;
    }
    if (/(windows phone|iemobile|wpdesktop)/i.test(ua)) {
        platform.desktop = false;
        platform.mobile = true;
        platform.windows = true;
    } else {
        if (/android/i.test(ua)) {
            platform.desktop = false;
            platform.mobile = true;
            platform.android = true;
        } else {
            if (/ip([ao]d|hone)/i.test(ua)) {
                platform.desktop = false;
                platform.mobile = true;
                platform.ios = true;
            }
        }
    }

    platform.tablet =
        /(?:iPad|PlayBook)/.test(ua) ||
        (platform.android && !/(?:Mobile)/.test(ua)) ||
        (platform.firefox && /(?:Tablet)/.test(ua));

    platform.touch = "ontouchstart" in window;
    platform.gamepads = "getGamepads" in navigator;
    return platform;
};

export const compareVersion = (v1, v2) => {
    v1 = v1.split(".");
    v2 = v2.split(".");
    let len = Math.max(v1.length, v2.length);

    while (v1.length < len) {
        v1.push("0");
    }
    while (v2.length < len) {
        v2.push("0");
    }

    for (let i = 0; i < len; i++) {
        let num1 = parseInt(v1[i]);
        let num2 = parseInt(v2[i]);

        if (num1 > num2) {
            return 1;
        } else if (num1 < num2) {
            return -1;
        }
    }
    return 0;
};

export const getIosVersion = () => {
    let ua = navigator.userAgent.toLowerCase();
    let ver = ua.match(/cpu iphone os (.*?) like mac os/);
    if (!ver) {
        alert("当前系统不是ios系统，无法获取ios版本号");
    } else {
        return ver[1].replace(/_/g, ".");
    }
};

export const getAndroidVersion = () => {
    let ua = navigator.userAgent.toLowerCase();
    if (/android/i.test(ua)) {
        let reg = /android [\d._]+/gi;
        let v_info = ua.match(reg);
        return (v_info + "").replace(/[^0-9|_.]/gi, "").replace(/_/gi, ".");
    } else {
        alert("当前系统不是android系统，无法获取android版本号");
    }
};

export function getDataByName(name, dataArr) {
    return dataArr.find((item) => item.name === name);
}
