export const TIMELINE_STATUS = {
    STOP: "stop",
    RUN: "run",
    PAUSE: "pause",
};
export const TIMLINE_SIGNAL = {
    ON_LOOP: "onLoop",
    ON_STOP: "onStop",
    ON_PAUSE: "onPause",
    ON_RESUME: "onResume",
    ON_CLEAR: "onClear",
};
function isArray(obj) {
    return Object.prototype.toString.call(obj) === "[object Array]";
}
/**
 * Interaction: 时间线管理
 */
export default class Interaction {
    constructor(context) {
        this.context = context;
    }

    timelinesStatus = {};
    configuration = {};

    emit(sceneID, timelineID, signal, ...argus) {
        if (this.configuration[sceneID]?.[timelineID]?.[signal]) {
            this.configuration[sceneID]?.[timelineID]?.[signal](...argus);
        }
    }

    setTimelineStatus(sceneID, timelineID, status) {
        if (this.timelinesStatus[sceneID]?.[timelineID]) {
            if (status === TIMELINE_STATUS.STOP) {
                delete this.timelinesStatus[sceneID][timelineID];
            } else {
                this.timelinesStatus[sceneID][timelineID] = status;
            }
        }
    }

    runInteraction(id, sceneID) {
        console.log("时间线", "uuid:", id, ", sceneID:", sceneID);
        this.context._app.events.call("edk:scene:interaction:run", sceneID, id);
        if (!this.timelinesStatus[sceneID]) this.timelinesStatus[sceneID] = {};
        this.emit(sceneID, id, "onStart");
        this.timelinesStatus[sceneID][id] = TIMELINE_STATUS.RUN;
    }

    run(id, sceneID) {
        if (!id) throw new Error("run interaction but has not timeline id");
        sceneID = sceneID || this.context._scene.defaultID;
        if (!sceneID) throw new Error("run interaction but has not scene id");

        if (isArray(id)) {
            id.forEach((id) => {
                if (isArray(id)) {
                    this.runInteraction(id[0], id[1]);
                } else {
                    this.runInteraction(id, sceneID);
                }
            });
        } else {
            this.runInteraction(id, sceneID);
            return this.addConfiguration.bind(this, id, sceneID);
        }
    }

    addConfiguration(id, sceneID, config) {
        if (!this.configuration[sceneID]) this.configuration[sceneID] = {};
        this.configuration[sceneID][id] = config;
    }

    removeConfiguration(sceneID, timelineID) {
        if (this.configuration[sceneID]?.[timelineID]) {
            delete this.configuration[sceneID][timelineID];
        }
    }

    onStop(sceneID, timelineID) {
        this.setTimelineStatus(sceneID, timelineID, TIMELINE_STATUS.STOP);
        this.emit(sceneID, timelineID, "onStop");
        this.removeConfiguration(sceneID, timelineID);
    }

    onLoop(sceneID, timelineID) {
        this.emit(sceneID, timelineID, "onLoop");
    }

    puase(sceneID, timelineID) {
        this.context._app.events.call(
            "edk:scene:interaction:pause",
            sceneID,
            timelineID
        );
        this.setTimelineStatus(sceneID, timelineID, TIMELINE_STATUS.PAUSE);
        this.emit(sceneID, timelineID, "onPause");
    }

    stop(sceneID, timelineID) {
        this.context._app.events.call(
            "edk:scene:interaction:stop",
            sceneID,
            timelineID
        );
        // TODO 测试该事件是否和onStop重复
        this.onStop();
    }

    resume(sceneID, timelineID) {
        this.context._app.events.call(
            "edk:scene:interaction:resume",
            sceneID,
            timelineID
        );
        this.emit(sceneID, timelineID, "onResume");
    }

    clear(sceneID) {
        // console.log("清除时间线")
        this.context._app.events.call("edk:scene:interaction:clear", sceneID);
        Object.keys(this.timelinesStatus[sceneID]).forEach((timelineID) => {
            this.setTimelineStatus(sceneID, timelineID, TIMELINE_STATUS.STOP);
            this.emit(sceneID, timelineID, "onClear");
        });
    }
}
