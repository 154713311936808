<template>
    <img alt="homa" :src="$$.assets('images/logo.png')" class="logo" :class="{
            logo: true,
            PcAdapt: !$$.platform.mobile,
        }" />
</template>

<script>
import {} from "vuex";

export default {
    name: "Logo",
    data() {
        return {};
    },
    mounted() {},
    methods: {},
    computed: {}
};
</script>

<style scoped lang="less">
@import "../../less/global/index.less";
.logo {
    position: absolute;
    top: (30 / @rem);
    left: (30 / @rem);
    z-index: 2;
    width: (132 / @rem);
    pointer-events: none;
}

.PcAdapt {
    top: 15px;
    left: 15px;
    width: 66px;
}
</style>