/**
 * 三级菜单
 * 第一级： 实体
 * 第二级： 组件
 * 第三级： 材质
 */
export const MENU_ARR = [
    {
        key:"1",
        name:"冰箱",
        nameEn: "REFRIGERATOR",
        timeline:"2361382852084367443",
        openDoorTimeline:"2361382019814916179",
        closeDoorTimeline:"2361382498083012691",
        children:[
            {
                key:"13",
                name:"面板",
                nameEn: "DOOR PANEL",
                timeline:"",
                children:[
                    {
                        key:"131",
                        name:"1晶莹白玻璃",
                        nameEn: "GLASS WHITE",
                        imgSrc:"",
                        timeline:"2361925488597794899",
                    },
                    {
                        key:"132",
                        name:"2黑色玻璃",
                        nameEn: "GLASS BLACK",
                        imgSrc:"",
                        timeline:"2361925923725377746",
                    },
                    {
                        key:"133",
                        name:"3炫黑钢U32",
                        nameEn: "METAL BLACK U32",
                        imgSrc:"",
                        timeline:"2361926188324094162",
                    },
                    {
                        key:"134",
                        name:"4仿拉丝银D08",
                        nameEn: "STREAMED SILVER D08 ",
                        imgSrc:"",
                        timeline:"2361926371142271059",
                    },
                    {
                        key:"135",
                        name:"5不锈钢深灰U10",
                        nameEn: "METAL GREY U10",
                        imgSrc:"",
                        timeline:"2361926477566443603",
                    },
                    {
                        key:"136",
                        name:"6闪耀银D12",
                        nameEn: "SHINY SILVER D12",
                        imgSrc:"",
                        timeline:"2361926599539949778",
                    },
                    {
                        key:"137",
                        name:"7葡萄黑光面B01",
                        nameEn: "GLOSSY BLACK B01",
                        imgSrc:"",
                        timeline:"2361926685771694163",
                    },
                    {
                        key:"138",
                        name:"8BEKO白光面A02",
                        nameEn: "GLOSSY WHITE A02",
                        imgSrc:"",
                        timeline:"2361927146709975250",
                    },
                    {
                        key:"139",
                        name:"9网纹灰C43",
                        nameEn: "PEBBLE GREY C43",
                        imgSrc:"",
                        timeline:"2361927244277874771",
                    },
                    {
                        key:"1310",
                        name:"10网纹蓝E28",
                        nameEn: "PEBBLE BLUE E28",
                        imgSrc:"",
                        timeline:"2361927331321217234",
                    },
                    {
                        key:"1311",
                        name:"11炫金钢U25",
                        nameEn: "METAL GREY U25",
                        imgSrc:"",
                        timeline:"2361927417861243090",
                    },
                ],
            },
            {
                key:"12",
                name:"侧板",
                nameEn: "SIDE PANEL",
                timeline:"",
                children: [
                    {
                        key:"121",
                        name:"1BEKO白单面压花A02",
                        nameEn: "WHITE A02",
                        imgSrc:"",
                        timeline:"2361929002887676114",
                    },
                    {
                        key:"122",
                        name:"2浅灰单面压花C03",
                        nameEn: "LIGHT GREY C03",
                        imgSrc:"",
                        timeline:"2361929085004808402",
                    },
                    {
                        key:"123",
                        name:"3深灰单面压花C28",
                        nameEn: "GREY C28",
                        imgSrc:"",
                        timeline:"2361929122009055442",
                    },
                    {
                        key:"124",
                        name:"4黑灰单面压花C38",
                        nameEn: "DARK GREY C38",
                        imgSrc:"",
                        timeline:"2361929178278789330",
                    },
                    {
                        key:"125",
                        name:"5葡萄黑单面压花B01",
                        nameEn: "BLACK B01",
                        imgSrc:"",
                        timeline:"2361929221439225939",
                    },
                ]
            },
            {
                key:"11",
                name:"把手",
                nameEn: "HANDLE",
                timeline:"",
                children: [
                    {
                        key:"111",
                        name:"1BEKO白ABS A02",
                        nameEn: "WHITE ABS A02",
                        imgSrc:"",
                        timeline:"2361930082716483667",
                    },
                    {
                        key:"112",
                        name:"2浅灰ABS C03",
                        nameEn: "LIGHT GREY ABS C03",
                        imgSrc:"",
                        timeline:"2361930298044711122",
                    },
                    {
                        key:"113",
                        name:"3深灰ABS C28",
                        nameEn: "GREY ABS C28",
                        imgSrc:"",
                        timeline:"2361930361716342867",
                    },
                    {
                        key:"114",
                        name:"4黑灰ABS C38",
                        nameEn: "DARK GREY ABS C38",
                        imgSrc:"",
                        timeline:"2361930437698257106",
                    },
                    {
                        key:"115",
                        name:"5葡萄黑ABS B01",
                        nameEn: "BLACK  ABS B01",
                        imgSrc:"",
                        timeline:"2361930488368595154",
                    },
                ]
            }, 
            {
                key:"14",
                name:"INTERIOR",
                nameEn: "INTERIOR DECORATION",
                timeline:"",
                children:[
                    {
                        key:"141",
                        name:"1海尔蓝K05",
                        nameEn: "CLEAR BLUE K05",
                        imgSrc:"",
                        timeline:"2361930974770495698",
                    },
                    {
                        key:"142",
                        name:"2无色透明Z01",
                        nameEn: "CLEAR Z01",
                        imgSrc:"",
                        timeline:"2361931409579311314",
                    },
                    {
                        key:"143",
                        name:"3冷灰透明M02",
                        nameEn: "CLEAR GREY M02",
                        imgSrc:"",
                        timeline:"2361931592422654035",
                    },
                    
                ]
            }
        ],
    },
    {
        key:"2",
        name:"冰柜",
        nameEn: "CHEST FREEZER",
        timeline:"2361382966478766290",
        openDoorTimeline:"2361381266315542738",
        closeDoorTimeline:"2361381423151055058",
        children:[
            {
                key:"22",
                name:"门板",
                nameEn: "DOOR PANEL",
                timeline:"",
                children:[
                    {
                        key:"221",
                        name:"1BEKO白单面压花A02",
                        nameEn: "WHITE A02",
                        imgSrc:"",
                        timeline:"2361932103459799123",
                    },
                    {
                        key:"222",
                        name:"2浅灰单面压花C03",
                        nameEn: "LIGHT GREY C03",
                        imgSrc:"",
                        timeline:"2361932256924139603",
                    },
                    {
                        key:"223",
                        name:"3葡萄黑光面B01",
                        nameEn: "GLOSSY BLACK B01",
                        imgSrc:"",
                        timeline:"2361932344435146835",
                    },
                    {
                        key:"224",
                        name:"4仿拉丝银D08",
                        nameEn: "STREAMED SILVER D08",
                        imgSrc:"",
                        timeline:"2361932405547204818",
                    },
                ]
            },
            {
                key:"23",
                name:"内箱",
                nameEn: "INNER CABINET",
                timeline:"",
                children:[
                    {
                        key:"231",
                        name:"1压花铝板",
                        nameEn: "PATTERNED SILVER ALUMINIUM",
                        imgSrc:"",
                        timeline:"2361933607614808147",
                    },
                    {
                        key:"232",
                        name:"2BEKO白光面铝板A02",
                        nameEn: "GLOSSY WHITE ALUMINIUM A02",
                        imgSrc:"",
                        timeline:"2361933720477237458",
                    },
                ]
            },
            {
                key:"21",
                name:"侧板组合",
                nameEn: "CABINET",
                timeline:"",
                children: [
                    {
                        key:"211",
                        name:"1金属A02+塑料A02",
                        nameEn: "WHITE A02 + WHITE ABS A02",
                        imgSrc:"",
                        timeline:"2361932599186686035",
                    },
                    {
                        key:"212",
                        name:"2金属B01+塑料B01",
                        nameEn: "GLOSSY BLACK B01 + BLACK ABS B01",
                        imgSrc:"",
                        timeline:"2361932878139359442",
                    },
                    {
                        key:"213",
                        name:"3金属A02+塑料C37",
                        nameEn: "WHITE A02 + BROWN GREY ABS C37",
                        imgSrc:"",
                        timeline:"2361932961555677267",
                    },
                    {
                        key:"214",
                        name:"4金属C03+塑料C31",
                        nameEn: "LIGHT GREY C03 + DARK GREY ABS C31",
                        imgSrc:"",
                        timeline:"2361933130063937619",
                    },
                ]
            },
        ],
    },
]