import axios from "axios";
import {appkey, appsecret, appGetDataBaseUri} from "../../core/constant";


export function getSceneData(scene_uid, isOnline=true) {
    let url = "";
    if(isOnline) {
        url = `//106.52.158.197:8000/scene/data?scene_uid=${scene_uid}`;
    }else {
        url = $$.assets(`data/${scene_uid}.rbs`)
    }
    return axios.get(url, {
        responseType: "arraybuffer",
    });
}


