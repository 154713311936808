import * as API from "./api/index.js";
import bindViewerEvents from "./bindViewerEvents.js";
import { MAIN_SCENE_ID, STOP_RIGHT_BUTTON_EVENT_TID } from "../core/constant.js";

function getUrlParam(name) {
    var reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)", "i");
    var r = decodeURI(window.location.search.substr(1)).match(reg);
    if (r != null) {
        return unescape(r[2]);
    }
    return null;
}

function loadData(id) {
    const urlId = getUrlParam("id");
    if (urlId) id = urlId;
    return API.getSceneData(id, $$.config.data === "online");
}

export default {
    // 初始化函数
    async initialize({ commit, dispatch }, { canvas, options }) {
        global.$$.app.initialize(canvas);
        const res = await loadData(MAIN_SCENE_ID);
        commit("setValue", { canvas, data: res.data });
        dispatch("loadScene");
    },
    validate({ commit, dispatch }) {
        commit("setValue", { validate: true });
        dispatch("loadScene");
    },
    loadScene({ state, commit, dispatch }) {
        const { canvas, data, validate } = state;
        if (canvas && data && validate) {
            global.$$.app.loadScene(data, {
                id: "main",
                dataProcessingHook: (data) => {
                    document.title = data.scene.info.name;
                    return data;
                },
            })({
                onProgressUpdate(progress) {
                    commit("Loading/updateProgress", Math.floor(progress * 100));
                },
                onLoadEnd() {
                    commit("Loading/loadSucess");
                    dispatch("bindViewerEvents");
                    dispatch("initViewerConfig");
                },
                isDefault: true,
            });
        }
    },
    // 初始化3D配置
    async initViewerConfig({ dispatch, state }) {
        $$.app.runInteraction(STOP_RIGHT_BUTTON_EVENT_TID);
    },
    // 绑定3D事件
    bindViewerEvents,
};
