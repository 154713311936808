// TODO: 热点移动事件
export const ANNOTATION_SIGNAL = {
    ON_CLICK: "onClick",
    ON_ENTER: "onEnter",
    ON_LEAVE: "onLeave",
};
function debounce(fn, time) {
    let timer = null;
    return function (...args) {
        if (timer) clearTimeout(timer);
        timer = setTimeout(() => {
            fn(...args);
        }, time);
    };
}
/**
 * Annotation: 热点管理
 */
export default class Annotation {
    constructor(context) {
        this.context = context;
    }

    /**
     * data: {
     *    [key: sceneID]: {
     *          [key: uuid] : annotationData
     *    }
     * }
     */
    data = {};

    /**
     * [{
     *    [key:signal]: callback: annotationData => void
     * }]
     */
    configuration = [];

    /**
     * config: {
     *    sceneID?: string,
     *    data: [annotationData: {
     *         sceneID?: string,
     *         uuid: string,
     *         [key:string]:any
     *    }]
     *    [key: signal]: callback: annotationData => void
     * }
     *
     * signal: {
     *    onClick, onEnter, onLeave
     * }
     */
    addAnnotationsListener(config) {
        const configIndex = this.configuration.length;
        const data = config.data;
        delete config.data;
        this.configuration.push(config);
        data.forEach((item) => {
            const { uuid } = item;
            let sceneID;
            sceneID = item.sceneID || config.sceneID || this.context._scene.defaultID;
            if (!this.data[sceneID]) this.data[sceneID] = {};
            this.data[sceneID][uuid] = {
                ...item,
                configIndex,
            };
        });
    }

    emit(sceneID, uuid, signal, ...argus) {
        const entityData = this.data[sceneID]?.[uuid];
        if (
            entityData &&
            Object.prototype.toString.call(entityData.configIndex) === "[object Number]" &&
            this.configuration[entityData.configIndex]?.[signal]
        ) {
            this.configuration[entityData.configIndex][signal](entityData, ...argus);
        }
    }

    onAnnotaionClick(sceneID, annotationID) {
        this.emit(sceneID, annotationID, "onClick");
    }

    onAnnotationEnter(sceneID, annotationID) {
        this.emit(sceneID, annotationID, "onEnter");
    }

    onAnnotationLeave(sceneID, annotationID) {
        this.emit(sceneID, annotationID, "onLeave");
    }

    onEntityClick = debounce((sceneID, entityID) => {
        this.emit(sceneID, entityID, "onClick");
    },200)

    entites = [];
    addEntitiesListener(config) {
        this.entites.push(...config.data.map(({ uuid }) => uuid))
        this.pushListener();
        this.addAnnotationsListener(config);
    }

    pushListener = debounce(() => {
        this.context._app.events.call(
            "edk:plugin:entity:selector:set:entities",
            this.entites
        );
    },300)
}
