<template>
    <div
        :class="{
            Menu: true,
            PcMenu: !$$.platform.mobile,
        }"
    >
        <header>
            <ul class="main-menu">
                <li
                    v-for="mainItem in mainArr"
                    :key="mainItem.key"
                    class="main-item"
                    :class="{
                        'main-item-active': mainItem.key === activeMainKey,
                    }"
                    @click="setMain(mainItem)"
                >
                    {{ mainItem.nameEn }}
                </li>
            </ul>
        </header>
        <footer class="footer">
            <div class="material-name" v-show="activeMaterialName">{{ activeMaterialName }}</div>
            <ul class="materials">
                <li
                    v-for="materialItem in materialArr"
                    :key="materialItem.key"
                    :class="{
                        'material-item': true,
                        'material-item-active': materialItem.key === activeMaterialKey,
                    }"
                    :style="{
                        backgroundImage:`url(${$$.assets(`images/material/${activeDataArr[0].key}/${activeDataArr[1].key}/${materialItem.key}.png`)})`
                    }"
                    @mousedown="start(materialItem, $event)"
                    @mousemove="move"
                    @mouseup="end"
                    @touchstart="start(materialItem, $event)"
                    @touchmove="move"
                    @touchend="end"
                >
                </li>
            </ul>
            <div class="buttons">
                <ul class="components">
                    <li
                        v-for="componentItem in componentArr"
                        :key="componentItem.key"
                        :class="{
                            'component-item': true,
                            'component-item-active': activeComponentKey === componentItem.key,
                        }"
                        @click="setComponent(componentItem)"
                    >
                        {{ componentItem.nameEn }}
                    </li>
                </ul>
                <div class="power">
                    <button
                        :class="{ 'power-button': true, 'power-button-active': !doorStatus }"
                        @click="setDoorStatus({ ...activeDataArr[0], status: false })"
                    >
                        Close
                    </button>
                    <div class="separator"></div>
                    <button
                        :class="{ 'power-button': true, 'power-button-active': doorStatus }"
                        @click="setDoorStatus({ ...activeDataArr[0], status: true })"
                    >
                        Open
                    </button>
                </div>
            </div>
        </footer>
        <div class="materialDetail" v-show="materialDetailShow">
            <div class="materialDetail-box">
                <div class="material-name" v-show="activeMaterialName">{{ activeMaterialName }}</div>
                <img
                    class="materialDetail-close"
                    :src="closeMaterialDetailImg"
                    alt="关闭"
                    @click="closeMaterialDetail"
                />
                <img class="materialDetail-main" :src="activeMaterialImg" :alt="activeMaterialName" />
            </div>
        </div>
    </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from "vuex";
class ClickEventHandler {
    constructor(e, data) {
        this.initEventData = e;
        this.data = data;
        this.timer = setTimeout(() => {
            this.handleLongClick();
        }, 500);
    }

    bind({ click, longClick, cancel }) {
        this.click = click;
        this.longClick = longClick;
        this.cancel = cancel;
        return this;
    }

    move(e) {
        const { screenX: startScreenX, screenY: startScreenY } = this.initEventData;
        const { screenX: endScreenX, screenY: endScreenY } = e;
        if (Math.abs(startScreenX - endScreenX) > 5 || Math.abs(startScreenY - endScreenY) > 5) {
            this.handleCancel();
        }
    }

    end() {
        if (this.timer) {
            this.handleShortClick();
        }
    }

    destroy() {
        this.timer = null;
        this.initEventData = null;
        this.data = null;
        this.click = null;
        this.longClick = null;
    }

    handleCancel() {
        clearTimeout(this.timer);
        if (this.cancel) {
            this.cancel(this.data);
        }
        this.destroy();
    }

    handleShortClick() {
        clearTimeout(this.timer);
        if (this.click) {
            this.click(this.data);
        }
        this.destroy();
    }

    handleLongClick() {
        if (this.longClick) {
            this.longClick(this.data);
        }
        this.destroy();
    }
}
let clickEventHandler = null;
export default {
    name: "Menu",
    data() {
        return {
            materialDetailShow: false,
            closeMaterialDetailImg: global.$$.assets("images/close_fill.png"),
        };
    },
    mounted() {},
    methods: {
        ...mapActions("Menu", ["setMain", "setComponent", "setMaterial"]),
        ...mapActions("Door", ["setDoorStatus"]),
        click(material) {
            this.setMaterial(material);
            this.destroyHandler();
        },
        longClick(material) {
            this.setMaterial(material);
            this.materialDetailShow = true;
            this.destroyHandler();
        },
        start(material, e) {
            clickEventHandler = new ClickEventHandler(e, material).bind({
                click: this.click,
                longClick: this.longClick,
                cancel: this.destroyHandler,
            });
        },
        move(e) {
            if (clickEventHandler) {
                clickEventHandler.move(e);
            }
        },
        end(e) {
            if (clickEventHandler) {
                clickEventHandler.end();
            }
        },
        destroyHandler() {
            clickEventHandler = null;
        },
        closeMaterialDetail() {
            this.materialDetailShow = false;
        },
    },
    computed: {
        ...mapGetters("Menu", ["activeDataArr", "activeMainKey"]),
        ...mapState("Menu", ["menus"]),
        mainArr() {
            return this.menus;
        },
        componentArr() {
            return this.activeDataArr[0].children;
        },
        activeComponentKey() {
            return this.activeDataArr[1]?.key;
        },
        materialArr() {
            return this.activeDataArr[1]?.children;
        },
        activeMaterialKey() {
            return this.activeDataArr[2]?.key;
        },
        activeMaterialName() {
            return this.activeDataArr[2]?.nameEn;
        },
        activeMaterialImg() {
            return $$.platform.mobile ?
            $$.assets(`images/detailMo/${this.activeDataArr[0].key}/${this.activeDataArr[1]?.key}/${this.activeDataArr[2]?.key}.jpg`):
            $$.assets(`images/detailPc/${this.activeDataArr[0].key}/${this.activeDataArr[1]?.key}/${this.activeDataArr[2]?.key}.jpg`);
        },
        doorStatus() {
            return this.$store.state.Door[this.activeDataArr[0].key];
        },
    },
};
</script>

<style scoped lang="less">
@import "../../less/global/index.less";
@unsel_background_color: rgba(0, 0, 0, 0.6);
@sel_background_color: rgba(236, 97, 16, 1);
.main-menu {
    display: flex;
    justify-content: center;
    position: absolute;
    top: (80 / @rem);
    width: 100vw;
    z-index: 2;

    .main-item {
        margin-right: (30 / @rem);
        width: (160 / @rem);
        height: (50 / @rem);
        font-size: (18 / @rem);
        line-height: (50 / @rem);
        text-align: center;
        background-color: @unsel_background_color;
        color: #fff;
        border-radius: (8 / @rem);
        cursor: pointer;
        white-space: nowrap;
        &:last-child {
            margin-right: 0;
        }
    }

    .main-item-active {
        background-color: @sel_background_color;
    }
}
.material-name {
    padding: 0 (40 / @rem);
    text-align: center;
    width:auto;
    white-space: nowrap;
    height: (40 / @rem);
    line-height: (40 / @rem);
    font-size: (18 / @rem);
    background-image: linear-gradient(to right, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0));
    color: #fff;
}
.footer {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: absolute;
    left: 0;
    right: 0;
    bottom: (24 / @rem);
    margin: auto;
    margin-bottom: env(safe-area-inset-bottom);
    width: (700 / @rem);
    z-index: 2;

    .buttons {
        display: flex;
        justify-content: space-between;
        width: 100%;
    }

    .components {
        display: flex;
        flex-wrap: wrap;
        // justify-content: space-around;
        width: (600 / @rem);

        .component-item {
            flex-grow: 1;
            margin-right: (20 / @rem);
            margin-bottom: (20 / @rem);
            width: (280 / @rem);
            height: (70 / @rem);
            line-height: (70 / @rem);
            font-size: (18 / @rem);
            color: #fff;
            text-align: center;
            border-radius: (6 / @rem);
            background-color: @unsel_background_color;
            cursor: pointer;

            &-active {
                background-color: @sel_background_color;
            }
        }
    }

    .material-name {
        text-align: center;
        // width: (360 / @rem);
        height: (40 / @rem);
        line-height: (40 / @rem);
        font-size: (18 / @rem);
        background-image: linear-gradient(to right, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0));
        color: #fff;
    }

    .materials {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        margin-bottom: (8 / @rem);

        .material-item {
            margin: (12 / @rem);
            border: (4 / @rem) solid #cccccc;
            width: (80 / @rem);
            height: (80 / @rem);
            border-radius: 50%;
            background-color: gray;
            background-position: center center;
            background-size: cover;

            cursor: pointer;

            &-active {
                border-width: (6/@rem);
                width: (84 / @rem);
                height: (84 / @rem);
                margin: (10 / @rem);
                border-color: red;
            }
        }
    }

    .power {
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        align-items: center;
        width: (100 / @rem);
        height: (160 / @rem);
        border-radius: (10 / @rem);
        background-color: @unsel_background_color;
        text-align: center;

        .separator {
            width: (80 / @rem);
            height: (2 / @rem);
            background-color: #fff;
        }

        &-button {
            padding: 0;
            font-size: (18 / @rem);
            margin: 0;
            width: (80 / @rem);
            height: (50 / @rem);
            line-height: (30 / @rem);
            color: #fff;
            background-color: transparent;
            box-shadow: unset;
            border: unset;
            border-radius: (6 / @rem);

            &-active {
                background-color: @sel_background_color;
            }
        }
    }
}

.materialDetail {
    position: absolute;
    top: 0%;
    left: 0%;
    right: 0;
    bottom: 0%;
    z-index: 10;
    background-color: #f2f2f2;

    &-box {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }

    &-close {
        position: absolute;
        top: 0;
        right: 0;
        transform: translateY(-125%);
        width: (80 / @rem);
        height: (80 / @rem);
        cursor: pointer;
    }

    &-main {
        margin: auto;
        max-width: 90vw;
        max-height: 85vh;
        border: 2px solid #cccccc;
    }
    .material-name {
        position: absolute;
        left: 50%;
        bottom: 0;
        transform: translate(-50%, 150%);
    }
}

.PcMenu {
    .main-menu {
        top: 40px;
        z-index: 2;
        .main-item {
            margin-right: 15px;
            width: 120px;
            height: 28px;
            line-height: 28px;
            font-size: 12px;
            border-radius: 4px;
        }
    }
    .footer {
        position: static;

        .buttons {
            flex-direction: column;
            position: absolute;
            left: 5vw;
            top: 50%;
            transform: translateY(-50%);
            z-index: 2;
        }

        .components {
            flex-direction: column;

            .component-item {
                margin-bottom: 18px;
                width: 260px;
                height: 28px;
                line-height: 28px;
                font-size: 12px;
                border-radius: 4px;
            }
        }

        .power {
            flex-direction: row;
            margin-left: 62px;
            width: 136px;
            height: 28px;
            border-radius: 4px;

            .separator {
                width: 1px;
                height: 24px;
            }
            &-button {
                margin-top: 1px;
                width: 60px;
                height: 22px;
                line-height: 22px;
                font-size: 12px;
                border-radius: 2px;
            }
        }
        .material-name {
            position: absolute;
            padding: 0px 26px;
            left: 50%;
            transform: translateX(-50%);
            bottom: 72px;
            z-index: 2;
            width: unset;
            height: 20px;
            line-height: 20px;
            font-size: 12px;
        }
        .materials {
            flex-wrap: nowrap;
            position: absolute;
            bottom: 10px;
            left: 0;
            right: 0;
            z-index: 2;
        }
        .material-item {
            flex-shrink: 0;
            margin: 6px;
            border-width: 2px;
            width: 40px;
            height: 40px;

            &-active {
                margin: 5px;
                width: 42px;
                height: 42px;
                border-width: 3px;
                border-color: red;
            }
        }
    }

    .materialDetail-close {
        transform: translateY(-150%);
        width: 40px;
        height: 40px;
    }
}
</style>
